import classNames from "classnames";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { useProfilePreview } from "PFApp/use_profile_preview";
import { Button } from "PFComponents/button";
import ProfilesAvatarStack from "PFComponents/profile_avatar/profiles_avatar_stack";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { PermissionRule, Profile } from "PFTypes";
import { useCallback } from "react";

import ChatWithIcon from "../../activities/show/parts/chat_with_icon";
import css from "./activity_profiles_avatar_stack.module.scss";

type ActivityProfilesAvatarStackProps = {
  className?: string;
  profiles: Profile[];
  qaId?: string;
  label?: string;
  style?: React.CSSProperties;
  handleActionClick?: VoidFunction;
  handleIconClick?: (profile: Profile) => void;
  actionLabel?: string;
  displayChatIcon?: boolean;
};

export const ActivityProfilesAvatarStack = ({
  className,
  profiles,
  label,
  style,
  handleActionClick,
  actionLabel,
  displayChatIcon,
  handleIconClick,
  qaId
}: ActivityProfilesAvatarStackProps) => {
  const { data: currentProfile } = useCurrentProfile();

  const { showPreview } = useProfilePreview();

  const isChatEnabled = useIsChatEnabled();

  const title = label ? <Typography variant="h5">{label}:</Typography> : null;

  const handleAvatarClick = (profile: Profile) => showPreview({ id: profile.id, profile });

  const getTooltipContent = useCallback(
    (profile: Profile) => (
      <div className={css.tooltip}>
        <Typography withMargin variant="bodyBold" tag="span">
          {getProfileName(profile)}
        </Typography>
        <Typography withMargin variant="bodyRegular" tag="span">
          {profile.permissions_group.display_as}
        </Typography>
      </div>
    ),
    []
  );

  const isProjectManager = (profile: Profile) =>
    !!profile.permissions_group.permissions.find(
      ({ name, permitted }) => name === PermissionRule.ProjectManager && permitted
    );

  return (
    <div style={style} className={classNames(css.avatar, className)} data-qa-id={qaId}>
      {handleActionClick ? (
        <div className={css.actionWrapper}>
          {title}
          <Button
            text={actionLabel || ""}
            onClick={handleActionClick}
            className={css.actionButton}
            small
            kind="secondary"
          />
        </div>
      ) : (
        title
      )}
      {/* @ts-ignore */}
      <ProfilesAvatarStack
        profiles={profiles}
        max={3}
        handleAvatarClick={handleAvatarClick}
        Icon={isChatEnabled && displayChatIcon && ChatWithIcon}
        handleIconClick={handleIconClick}
        currentProfile={currentProfile}
        getTooltipContent={getTooltipContent}
        getIsAvatarHighlighted={isProjectManager}
      />
    </div>
  );
};
