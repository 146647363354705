import { BookingPill } from "PFApp/components/pills/booking_pill";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { getContrastingTextColor } from "PFCore/utilities/color";
import { validateCategoryIcon } from "PFModules/admin/booking/categories/booking_categories.utils";
import React from "react";

import css from "./category_badge.module.scss";

type CategoryBadgeProps = { categoryId?: number };

const CategoryBadge = ({ categoryId }: CategoryBadgeProps) => {
  const { getBookingCategory } = useBookingCategories();
  const category = getBookingCategory(categoryId);
  const fillColor = category ? getContrastingTextColor(category.color, 1).hex() : undefined;
  const categoryIcon = category ? validateCategoryIcon(category.icon) : "locked";

  // TODO: [SP-2975] Use Table component in BM Approvals tab
  // Use BookingPill logic to display category icon (svg fill is overwritten by a deprecated css)
  return (
    <BookingPill category={category} withCategoryIcon={false} className={css.root}>
      {!!categoryIcon && (
        <Icon name={categoryIcon} size="xs" style={{ fill: fillColor }} className={css.categoryIcon} />
      )}
      <Typography variant="labelRegular" clipOverflow title={category?.display_as}>
        {category?.display_as}
      </Typography>
    </BookingPill>
  );
};

export default CategoryBadge;
