import { Query, QueryKey, useQueryClient } from "@tanstack/react-query";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import { Booking, Collection } from "PFCore/types";

import { historyKeys } from "../bookings/history/query_keys";
import { bookingKeys, bookingTemplateKeys } from "../bookings/query_keys";
import profileKeys from "./query_keys";
import { Profile, ProfileBookingsIndex } from "./use_profiles_bookings";

export const useProfileInvalidate = () => {
  const cache = useQueryClient();

  const getCachedBookingsByProfileId = (profileId: number | string): Booking[] => {
    const cachedProfileBookings = cache
      .getQueryCache()
      .findAll({ queryKey: profileKeys.bookings(Number(profileId)) })
      .map(({ state }: Query<[Profile, Booking[]] | Collection<Booking[]>>) => state.data)
      .filter(Boolean)
      .map((entry) => {
        if (isNil(entry)) {
          return [];
        }
        const bookings = "entries" in entry ? entry.entries : (entry || [])[1];
        return (bookings ?? []) as Booking[];
      })
      .flat();
    const cachedSingleBookings = cache
      .getQueriesData<Booking>({ predicate: bookingKeys.isBookingQuery })
      .filter(([, booking]) => booking?.profile_id === profileId)
      .map(([, booking]) => booking)
      .filter(Boolean) as Booking[];
    return [...cachedProfileBookings, ...cachedSingleBookings];
  };

  return {
    invalidateProfileWithBooking: (bookingId: number) => {
      const [cachedProfileKeys]: [QueryKey | undefined, ProfileBookingsIndex?] = cache
        .getQueriesData<ProfileBookingsIndex>({ predicate: profileKeys.isBookingQuery })
        .find(
          ([, profileBookings]) =>
            !!Object.values(profileBookings || {})
              .flat()
              .find(({ id }) => bookingId === id)
        ) || [undefined];

      if (!cachedProfileKeys) {
        return;
      }

      const profileId = profileKeys.getProfileId(cachedProfileKeys);

      return profileId && cache.invalidateQueries({ queryKey: profileKeys.profile(profileId) });
    },
    invalidateCurrentProfile: () => cache.invalidateQueries({ queryKey: profileKeys.me() }),
    invalidateProfile: (profileId: number) =>
      cache.invalidateQueries({ queryKey: profileKeys.profile(profileId) }),
    invalidateProfileBookings: (profile?: Pick<Profile, "id" | "activityId">) => {
      if (!profile) {
        return cache.invalidateQueries({
          predicate: profileKeys.isBookingQuery
        });
      }
      return cache.invalidateQueries({
        queryKey: profileKeys.bookings(
          profile.id,
          !isNil(profile.activityId) ? { activityId: profile.activityId } : undefined
        )
      });
    },
    removeProfileBookingsCache: (profile?: Pick<Profile, "id" | "activityId">) => {
      if (!profile) {
        cache.removeQueries({
          predicate: profileKeys.isBookingQuery
        });
        return;
      }
      cache.removeQueries({
        queryKey: profileKeys.bookings(
          profile.id,
          !isNil(profile.activityId) ? { activityId: profile.activityId } : undefined
        )
      });
    },
    invalidateAllProfiles: () =>
      cache.invalidateQueries({
        queryKey: profileKeys.all()
      }),
    invalidateBookingsForProfile: (id: number | string) => {
      const bookings = getCachedBookingsByProfileId(id);
      const bookingIds = bookings.map(({ id }) => id);

      const queryKeys = uniq(bookingIds).map((id) => bookingKeys.single(id));
      queryKeys.forEach((queryKey) =>
        cache.invalidateQueries({
          queryKey
        })
      );
    },
    invalidateBookingsDataByProfileId: (profileId: number) => {
      const bookings = getCachedBookingsByProfileId(profileId);
      const queryKeys = uniq([
        ...bookings.flatMap(({ id }) => [bookingKeys.single(id), historyKeys.ofBooking(id)]),
        ...bookings.map(({ booking_template_id: bookingTemplateId }) =>
          bookingTemplateKeys.single(bookingTemplateId)
        )
      ]);
      return Promise.all([
        queryKeys.map((queryKey) =>
          cache.invalidateQueries({
            queryKey
          })
        )
      ]);
    },
    removeProfileWarningsCache: () =>
      cache.removeQueries({
        predicate: profileKeys.isWarningsQuery
      }),
    removeProfileAvailabilitiesCache: () =>
      cache.removeQueries({
        predicate: profileKeys.isAvailabilitiesQuery
      })
  };
};
