import classNames from "classnames";
import { Button } from "PFComponents/button";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { isProfileActive } from "PFCore/helpers/profile";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import css from "./profiles_avatar_stack.module.scss";

const ProfilesAvatarStack = ({
  profiles,
  currentProfile,
  style,
  avatarSize = 40,
  max = 3,
  handleAvatarClick,
  noOverlap,
  Icon,
  handleIconClick,
  getTooltipContent,
  getIsAvatarHighlighted
}) => {
  const plusProfiles = profiles?.length - max;

  const [displayPlus, setDisplayPlus] = useState(plusProfiles > 0);

  useEffect(() => {
    setDisplayPlus(plusProfiles > 0);
  }, [plusProfiles]);

  if (profiles?.length <= 0) {
    return null;
  }

  const displayedProfiles = displayPlus ? profiles?.slice(0, max) : profiles;
  const isCurrentProfile = (id) => id === currentProfile?.id;

  // Avatar size hardcoded to make the css work
  return (
    <div style={style} className={css.wrapper}>
      {displayedProfiles?.map((profile) => (
        <div
          key={profile.id}
          style={{ height: avatarSize }}
          className={classNames(css.avatar, {
            [css.collapsed]: !noOverlap && displayPlus,
            [css.pointy]: handleAvatarClick
          })}
        >
          <ProfileAvatar
            profile={profile}
            size={avatarSize}
            onClick={
              isProfileActive(profile) && handleAvatarClick
                ? (event) => handleAvatarClick(profile, event)
                : null
            }
            Icon={!isCurrentProfile(profile.id) && isProfileActive(profile) && Icon}
            handleIconClick={isProfileActive(profile) && (() => handleIconClick(profile))}
            isHighlighted={getIsAvatarHighlighted?.(profile)}
            tooltipContent={getTooltipContent?.(profile)}
          />
        </div>
      ))}
      {plusProfiles > 0 && (
        <Button kind="text" rootClassName={css.plusText} onClick={() => setDisplayPlus(!displayPlus)}>
          {displayPlus ? `+ ${plusProfiles}` : "-"}
        </Button>
      )}
    </div>
  );
};

ProfilesAvatarStack.propTypes = {
  /** Array of profiles that will be used to generate avatars. */
  profiles: PropTypes.array,
  /** Extra style to apply to the root element */
  style: PropTypes.object,
  /** Maximum number of avatars to show */
  max: PropTypes.number,
  /** handler to call on click (passes a profile) */
  handleAvatarClick: PropTypes.func,
  /** customize avatar size **/
  avatarSize: PropTypes.number,
  /** do not show profiles overlapping each other **/
  noOverlap: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleIconClick: PropTypes.func,
  currentProfile: PropTypes.object,
  getTooltipContent: PropTypes.func,
  getIsAvatarHighlighted: PropTypes.func
};

export default ProfilesAvatarStack;
