/* eslint-disable camelcase, id-length */
import classNames from "classnames";
import $ from "jquery";
import { useExperienceLabelMap } from "PFApp/hooks/use_experience_label_map";
import { useSourceLabel } from "PFApp/hooks/use_source_label";
import { RatingIcon } from "PFComponents/custom_values_list/rating_icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography/typography";
import { requiresApproval } from "PFCore/helpers/custom_value";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useProfileCustomValue } from "PFCore/hooks/queries/custom_fields/use_profile_custom_value";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { ApiRoute } from "PFCore/utilities/routes";
import CheckIcon from "PFIcons/check_3.svg";
import CheckCredlyIcon from "PFIcons/check_credly.svg";
import DumbbellIcon from "PFIcons/dumbbell.svg";
import StarIcon from "PFIcons/star_2.svg";
import colors from "PFTheme/tokens/colors";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionIcon } from "../../action_icon";
import { InternalExperiencesBox } from "../../expandable_badge/internal_experiences_box";
import Certificates from "./certificates";
import css from "./skill_details_tooltip_content.module.scss";

const SkillBadgeMetadata = ({ badgeProps, profileId, currentProfile, close, style, isEditMode }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { id, rankable, text, value } = badgeProps;
  const title = text || value;
  const { t } = useTranslation("core", { keyPrefix: "skillBadge" });

  const [internalExperiencesArray, setInternalExperiencesArray] = useState([]);
  const experienceLabelMap = useExperienceLabelMap();
  const { getSourceLabel } = useSourceLabel();
  const { data: skill } = useProfileCustomValue(id, profileId);

  const {
    createdAt,
    source,
    assessmentSource,
    certificateIds,
    assessed,
    developmental,
    top,
    internalExperienceIds,
    experience,
    updatedAt,
    activitiesCount,
    profilesCount,
    emVerified: managerAssessed,
    description
  } = skill || {};

  const { formatDate } = useDateFormatter();
  const skillsCustomType = currentProfile.custom_types.find(({ name }) => name === "skills");

  useEffect(() => {
    // TODO: [ENG-2544] Optimize once _ids filter is working on API
    // url: ApiRoute('/api/activities'),
    // data: {_ids: internalExperiences },
    const promises = internalExperienceIds?.map((item) =>
      $.ajax({
        url: ApiRoute(`/api/activities/${item}`),
        api_version: 2
      })
    );

    if (promises) {
      Promise.allSettled(promises).then((result) => {
        setInternalExperiencesArray(
          result.filter((resp) => resp.status === "fulfilled").map((resp) => resp.value)
        );
      });
    }
  }, [internalExperienceIds]);

  const experienceTemplateId = currentProfile?.templates?.find((template) => template.key === "work")?.id;
  const experienceTemplateAccessLevel = ["ro", "rw"].includes(
    currentProfile?.permissions_group?.cross_group_permissions[currentProfile?.permissions_group?.id]
      ?.activities_access_levels?.[experienceTemplateId]
  );

  const isValidatedIconVisible = currentAccount.profile_view_customization.validated_skill_text;
  const translatedSource = getSourceLabel(source);

  return (
    <div className={css.wrapper} style={style}>
      <div className={css.header}>
        <Typography withMargin variant="h4" className={css.title}>
          {title}
          {requiresApproval(skillsCustomType, badgeProps) && ` (${t("skillNotApproved")})`}
        </Typography>
        <div className={css.icons}>
          <span className={css.assessmentIcons}>
            {top && (
              <span title={t("topSkill")}>
                <StarIcon className={classNames(css.icon, css.top)} height={15} />
              </span>
            )}
            {developmental && (
              <span title={t("developmentalSkill")}>
                <DumbbellIcon className={classNames(css.icon, css.developmental)} size={20} />
              </span>
            )}
            {isValidatedIconVisible &&
              assessed &&
              (assessmentSource === "credly" ? (
                <span title={t("assessment.credly")}>
                  <CheckCredlyIcon className={classNames(css.icon, css.verified, css.credly)} size={20} />
                </span>
              ) : (
                <span title={t("assessment.assessed")}>
                  <CheckIcon className={classNames(css.icon, css.verified)} size={20} />
                </span>
              ))}
            {managerAssessed && (
              <span title={t("assessment.feedback")}>
                <CheckIcon className={classNames(css.icon, css.managerAssessment)} size={20} />
              </span>
            )}
          </span>
          {close && <ActionIcon name="cross" size="md" color="paletteWhite0" onClick={close} />}
        </div>
      </div>
      <div className={css.content}>
        <div className={css.topSection}>
          {description && (
            <Typography withMargin variant="bodyRegular" tag="span" className={css.description}>
              {description}
            </Typography>
          )}
          <div className={css.propertiesTable}>
            <div className={css.col}>
              <div className={css.row}>
                <Typography withMargin variant="bodyRegular" tag="p" className={css.label}>
                  {t("labels.added")}
                </Typography>
                <Typography withMargin className={css.data} variant="bodyBold">
                  {createdAt && formatDate(createdAt)}
                </Typography>
              </div>
              {translatedSource && (
                <div className={css.row}>
                  <Typography withMargin variant="bodyRegular" tag="p" className={css.label}>
                    {t("labels.source")}
                  </Typography>
                  <Typography withMargin className={css.data} variant="bodyBold">
                    {translatedSource}
                  </Typography>
                </div>
              )}
              {experienceTemplateAccessLevel && (
                <div className={css.row}>
                  <Typography withMargin variant="bodyRegular" tag="p" className={css.label}>
                    {t("labels.used")}
                  </Typography>
                  <div
                    className={css.data}
                    style={internalExperienceIds?.length ? { cursor: "pointer" } : {}}
                  >
                    <Tooltip
                      disabled={!internalExperienceIds?.length}
                      content={
                        <InternalExperiencesBox
                          internalExperiences={internalExperiencesArray}
                          profileId={profileId}
                          closeOnClick={close}
                        />
                      }
                      trigger="click"
                      theme="pf-light"
                      interactive
                    >
                      <div>{t("internal", { count: internalExperienceIds?.length || 0 })}</div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
            <div className={css.col}>
              <div className={css.row}>
                <Typography withMargin variant="bodyRegular" className={css.label} tag="p">
                  {t("labels.lastEdited")}
                </Typography>
                <Typography withMargin className={css.data} variant="bodyBold">
                  {updatedAt && formatDate(updatedAt)}
                </Typography>
              </div>
              {profilesCount - 1 > 0 && (
                <div className={css.row}>
                  <Typography withMargin variant="bodyRegular" className={css.label} tag="p">
                    {t("labels.others")}
                  </Typography>
                  <Typography withMargin className={css.data} variant="bodyBold">
                    {profilesCount - 1}
                  </Typography>
                </div>
              )}
              <div className={css.row}>
                <Typography withMargin variant="bodyRegular" className={css.label} tag="p">
                  {t("labels.requiredIn")}
                </Typography>
                <Typography withMargin className={css.data} variant="bodyBold">
                  {activitiesCount}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        {rankable && skill && (
          <div className={css.skillRating}>
            <RatingIcon
              customValue={skill}
              customType={skillsCustomType}
              color={colors.paletteWhite0}
              size="lg"
              isEditMode={isEditMode}
            />
            <Typography withMargin variant="h4" className={css.experience}>
              {experienceLabelMap[experience]}
            </Typography>
          </div>
        )}
        {certificateIds?.length > 0 && <Certificates profileId={profileId} customValueIds={[id]} />}
      </div>
    </div>
  );
};

SkillBadgeMetadata.propTypes = {
  isDisabled: PropTypes.bool,
  profileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentProfile: PropTypes.shape({
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string
      })
    ),
    permissions_group: PropTypes.shape({
      id: PropTypes.number,
      cross_group_permissions: PropTypes.object
    }),
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number.isRequired,
    custom_types: PropTypes.array.isRequired
  }),
  badgeProps: PropTypes.object.isRequired,
  style: PropTypes.object,
  close: PropTypes.func,
  isEditMode: PropTypes.bool
};

export default SkillBadgeMetadata;
