import { useSourceLabel } from "PFApp/hooks/use_source_label";
import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { useCustomValueInsights } from "PFCore/hooks/queries/custom_fields/use_custom_value_insights";
import { useProfileCustomValue } from "PFCore/hooks/queries/custom_fields/use_profile_custom_value";
import PropTypes from "prop-types";
import { useState } from "react";

import SkillBadgeMetadata from "./skill_badge_metadata";

const ExpandableBadge = ({
  id,
  icon,
  text = "",
  displayValue,
  kind,
  showTooltip,
  topRightIcon,
  openByDefault,
  profileId,
  currentProfile,
  style
}) => {
  // pass is expanded as prop as the regular skills are going ot be closed by default
  const [isExpanded, setIsExpanded] = useState(openByDefault || false);
  const { getSourceLabel } = useSourceLabel();

  const { data: skillData, isLoading: isLoadingCustomValue } = useProfileCustomValue(id, profileId, {
    enabled: isExpanded
  });
  const { data: skillInsights, isLoading: isLoadingInsights } = useCustomValueInsights(id, {
    enabled: isExpanded
  });

  const expansionBox = skillData && skillInsights && (
    <SkillBadgeMetadata
      addedOn={skillData.createdAt}
      internalExperiences={skillData.internalExperienceIds}
      usedFor={skillData.seniority} // seniority is received in months
      // remove 1 on matches count as the current user is counted
      skillSource={getSourceLabel(skillData.source ?? null)}
      othersWithIt={skillData.profilesCount - 1}
      rankingValues={skillInsights.experienceStats}
      ownExperience={skillData.experience}
      isDisabled={kind === "disabled"}
      profileId={profileId}
      currentProfile={currentProfile}
    />
  );

  const isLoading = isLoadingCustomValue || isLoadingInsights;

  return (
    <SimpleBadge
      style={style}
      expandable
      isExpanded={isExpanded}
      handleExpanded={setIsExpanded}
      expansionBoxChild={expansionBox}
      icon={icon}
      text={text}
      displayValue={displayValue}
      kind={kind}
      showTooltip={showTooltip}
      topRightIcon={topRightIcon}
      isLoading={isLoading}
    />
  );
};

ExpandableBadge.propTypes = {
  id: PropTypes.number,
  icon: PropTypes.object,
  text: PropTypes.string.isRequired,
  displayValue: PropTypes.element,
  kind: PropTypes.string,
  showTooltip: PropTypes.bool,
  topRightIcon: PropTypes.any,
  openByDefault: PropTypes.bool,
  profileId: PropTypes.number,
  currentProfile: PropTypes.object,
  style: PropTypes.object
};

export default ExpandableBadge;
