/* eslint-disable camelcase */
import { useAppContext } from "PFApp/app_context";
import { Button } from "PFComponents/button";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import Tracking from "PFCore/helpers/tracking";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { customValuesShapePropType, scoresShapePropType } from "../../../base/prop_types/common_prop_types";
import css from "./profile_card_skills.module.scss";

const SKILLS_LIMIT = 5;

const CustomMoreButton = ({
  currentAccount,
  profile,
  trackEventName,
  shownSkillsCount = 0,
  isBlindModeOn,
  userId
}) => {
  const { t } = useTranslation("core", { keyPrefix: "profileCardSkills" });

  const { dispatch } = useAppContext();

  const numberOfSkills =
    (profile.statistics?.skills_count || (profile.skills || []).length || 0) - shownSkillsCount;
  const onClick = () => {
    dispatch({
      type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
      payload: { id: profile.id, profile }
    });

    if (trackEventName && currentAccount) {
      new Tracking(currentAccount, userId).recordEvent(trackEventName, {
        profile_id: profile.id,
        right_bar: true
      });
    }
  };

  return (
    numberOfSkills > 0 && (
      <Button
        kind="text"
        className={css.more}
        onClick={!isBlindModeOn && onClick}
        data-qa-id="ProfileCard.more"
      >
        {t("moreSkillsLabel", { numberOfSkills })}
      </Button>
    )
  );
};

CustomMoreButton.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    skills: PropTypes.array,
    statistics: PropTypes.shape({
      skills_count: PropTypes.number
    })
  }),
  shownSkillsCount: PropTypes.number,
  trackEventName: PropTypes.oneOf(["profiles-list-profile-show"]),
  isBlindModeOn: PropTypes.bool,
  currentAccount: PropTypes.object,
  userId: PropTypes.number.isRequired
};

const ProfileCardSkills = ({
  style,
  sort,
  currentAccount,
  profileResponse,
  currentProfile,
  outlook,
  moreInfoTrackEvent,
  isBlindModeOn
}) => {
  const { t } = useTranslation("core", { keyPrefix: "profileCardSkills" });
  const {
    profile,
    custom_values: { direct, indirect, related }
  } = { custom_values: { direct: [], indirect: [], related: [] }, ...profileResponse };
  const href = outlook ? `/profiles/${profile.id}` : `profiles/${profile.id}`;
  const target = outlook ? "_blank" : null;

  const data = useMemo(() => {
    if (direct.length || related.length || indirect.length) {
      return [
        {
          header: t("matchingAttributes"),
          skillsToShow: direct.map((item) => ({ ...item, ...item?.custom_value })),
          isTopSkills: false,
          customButton: outlook && (
            <a href={href} target={target} data-qa-id="ProfileCard.more">
              more
            </a>
          )
        },
        {
          header: t("relatedAttributes"),
          skillsToShow: related.map((item) => ({ ...item, ...item?.custom_value })),
          isTopSkills: false
        },
        {
          header: t("indirectAttributes"),
          skillsToShow: indirect.map((item) => ({ ...item, ...item?.custom_value })),
          isTopSkills: false
        }
      ].filter((row) => row.skillsToShow?.length);
    }
    const skills = profile.skills || [];
    const topSkills = skills.filter(({ top }) => top);
    const isTopSkills = topSkills.length > 0;
    const skillsToShow = isTopSkills ? topSkills : skills;
    if (!skillsToShow.length) {
      return [];
    }
    return [
      {
        header: isTopSkills ? t("coreSkills") : t("skills"),
        skillsToShow,
        isTopSkills,
        customButton: outlook ? (
          <a href={href} target={target} data-qa-id="ProfileCard.more">
            {t("translation:more")}
          </a>
        ) : (
          <CustomMoreButton
            currentAccount={currentAccount}
            profile={profile}
            shownSkillsCount={Math.min(isTopSkills ? topSkills.length : skills.length, SKILLS_LIMIT)}
            trackEventName={moreInfoTrackEvent}
            isBlindModeOn={isBlindModeOn}
            userId={currentProfile.id}
          />
        )
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileResponse, t]);

  if (!data.length) {
    return null;
  }

  const skillsType = currentProfile.custom_types.find(({ name }) => name === "skills");

  return (
    <div className={css.wrap} style={style}>
      {data.map(({ skillsToShow, customButton, header, isTopSkills }, index) => (
        <div className={css.profileCardSection} key={header}>
          <h3 className={css.header} style={{ msGridRow: index + 1 }}>
            {header}
          </h3>
          {skillsToShow.length > 0 && (
            <CustomValuesList
              style={{ msGridRow: index + 1 }}
              rootClassName={css.valuesList}
              sort={sort}
              type={skillsType}
              customValues={skillsToShow}
              showTips={true}
              displayAttributeLevel={true}
              customButton={customButton}
              expandable={isTopSkills}
              profileId={profile?.id}
              moreLimit={SKILLS_LIMIT}
              currentProfile={currentProfile}
              currentAccount={currentAccount}
              showModal={!outlook}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const profileResponseShapePropType = PropTypes.shape({
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  scores: scoresShapePropType,
  custom_values: customValuesShapePropType,
  optional_fields: PropTypes.shape({
    available_minutes: PropTypes.number
  })
});
ProfileCardSkills.propTypes = {
  style: PropTypes.object,
  sort: PropTypes.bool,
  moreLimit: PropTypes.number,
  currentAccount: PropTypes.object,
  profileResponse: profileResponseShapePropType.isRequired,
  currentProfile: PropTypes.object.isRequired,
  outlook: PropTypes.bool,
  moreInfoTrackEvent: PropTypes.string,
  isBlindModeOn: PropTypes.bool
};

export default ProfileCardSkills;
