import groupBy from "lodash/groupBy";
import map from "lodash/map";
import { CustomValuePill } from "PFComponents/custom_value_pill";
import { Tile, TileGroup } from "PFComponents/tile";
import { Typography } from "PFComponents/typography";
import { requiresApproval } from "PFCore/helpers/custom_value";
import { ActivityPureCustomValue, CustomType, Importance } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./match_attributes.module.scss";

type AttributeTileProps = {
  isRankable: boolean;
  customType: CustomType;
  matchKeywords: ActivityPureCustomValue[];
  onAttributeClick: (attr: ActivityPureCustomValue) => void;
};

const ORDERED_IMPORTANCES = [Importance.Essential, Importance.Supporting, Importance.Required];

export const AttributeTile = ({
  isRankable,
  matchKeywords,
  customType,
  onAttributeClick
}: AttributeTileProps) => {
  const { t } = useTranslation();
  const groupedKeywords = groupBy(matchKeywords, "importance");

  const importanceLabelMap = {
    [Importance.Supporting]: t("importance.supporting"),
    [Importance.Essential]: t("importance.essential"),
    [Importance.Required]: t("importance.hardFilter")
  };

  return (
    <TileGroup>
      {map(ORDERED_IMPORTANCES, (importance) => {
        const keywords = groupedKeywords[importance] || [];

        if (keywords.length === 0) {
          return null;
        }

        return (
          <Tile className={css.attributeTile} border paddingVariant="md">
            <Typography withMargin variant="labelRegular">
              {importanceLabelMap[importance]}
            </Typography>
            {map(keywords, (keyword) => (
              <CustomValuePill
                name={keyword.text}
                isRequirement={isRankable}
                isPendingApproval={requiresApproval(customType, keyword)}
                experience={isRankable ? keyword.required_experience : undefined}
                onClick={() => onAttributeClick(keyword)}
              />
            ))}
          </Tile>
        );
      })}
    </TileGroup>
  );
};
