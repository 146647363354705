import classNames from "classnames";
import { RankingIndicator } from "PFComponents/ranking_indicator/ranking_indicator";
import Tooltip from "PFComponents/tooltip/tooltip";
import { dateFromMonths } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { ApiRoute } from "PFCore/utilities/routes";
import QuestionIcon from "PFIcons/question_rounded.svg";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InternalExperiencesBox } from "./internal_experiences_box";
import css from "./skill_badge_metadata.module.scss";

const TooltipInfo = () => {
  const { t } = useTranslation("core", { keyPrefix: "skillBadge.expandable" });
  return (
    <div className={css.tooltipWrapper}>
      <h2 className={css.tooltipHeader}>{t("tooltipTitle")}</h2>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("addedOn")}</div>
        <div className={css.tooltipData}>{t("addedOnDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("usedFor")}</div>
        <div className={css.tooltipData}>{t("usedForDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("skillsUsedOn")}</div>
        <div className={css.tooltipData}>{t("skillsUsedOnDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("showInternalExperiences")}</div>
        <div className={css.tooltipData}>{t("showInternalExperiencesDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("skillSource")}</div>
        <div className={css.tooltipData}>{t("skillSourceDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <ul className={css.list}>
          <li className={css.tooltipLine}>
            <div className={css.tooltipMain}>{t("userAdded")}</div>
            <div className={css.tooltipData}>{t("userAddedDescription")}</div>
          </li>
          <li className={css.tooltipLine}>
            <div className={css.tooltipMain}>{t("skillSuggestion")}</div>
            <div className={css.tooltipData}>{t("skillSuggestionDescription")}</div>
          </li>
          <li className={css.tooltipLine}>
            <div className={css.tooltipMain}>{t("hrAdded")}</div>
            <div className={css.tooltipData}>{t("hrAddedDescription")}</div>
          </li>
          <li className={css.tooltipLine}>
            <div className={css.tooltipMain}>{t("internalExperience")}</div>
            <div className={css.tooltipData}>{t("internalExperienceDescription")}</div>
          </li>
        </ul>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("othersWithIt")}</div>
        <div className={css.tooltipData}>{t("othersWithItDescription")}</div>
      </div>
      <div className={css.tooltipLine}>
        <div className={css.tooltipMain}>{t("ranking")}</div>
        <div className={classNames(css.tooltipData, css.italic)}>{t("rankindDescription")}</div>
      </div>
    </div>
  );
};

const SkillBadgeMetadata = ({
  addedOn,
  usedFor,
  skillSource,
  othersWithIt,
  internalExperiences,
  rankingValues,
  ownExperience,
  isDisabled,
  profileId,
  currentProfile
}) => {
  const { t } = useTranslation("core", { keyPrefix: "skillBadge.expandable" });

  const experienceTemplateId = currentProfile?.templates?.find((template) => template.key === "work")?.id;
  const experienceTemplateAccessLevel = ["ro", "rw"].includes(
    currentProfile?.permissions_group?.cross_group_permissions[currentProfile?.permissions_group?.id]
      ?.activities_access_levels?.[experienceTemplateId]
  );
  const { formatDate } = useDateFormatter();

  const [internalExperiencesArray, setInternalExperiencesArray] = useState([]);

  useEffect(() => {
    const promises = internalExperiences?.map((item) =>
      $.ajax({
        url: ApiRoute(`/api/activities/${item}`),
        api_version: 2
      })
    );

    if (promises) {
      Promise.allSettled(promises).then((result) => {
        setInternalExperiencesArray(
          result.filter((resp) => resp.status === "fulfilled").map((resp) => resp.value)
        );
      });
    }
  }, [internalExperiences]);

  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        {t("title")}
        <Tooltip content={<TooltipInfo />} className={css.tooltip} trigger="click">
          <div>
            <QuestionIcon height={20} width={20} />
          </div>
        </Tooltip>
      </div>
      <div className={css.line}>
        <div>{t("addedOn")}</div>
        <div className={css.data}>{formatDate(addedOn)}</div>
      </div>
      {usedFor > 0 && (
        <div className={css.line}>
          <div>{t("usedFor")}</div>
          <div className={css.data}>{dateFromMonths(usedFor)}</div>
        </div>
      )}
      {skillSource && (
        <div className={css.line}>
          <div>{t("skillSource")}</div>
          <div className={css.data}>{skillSource}</div>
        </div>
      )}
      {internalExperiencesArray.length > 0 && experienceTemplateAccessLevel && (
        <div className={css.lineGroup}>
          <div className={css.line}>
            <div>{t("skillsUsedOn")}</div>
            <div className={css.data}>
              {t("internalExperiences", { count: internalExperiencesArray.length })}
            </div>
          </div>
          <Tooltip
            content={
              <InternalExperiencesBox internalExperiences={internalExperiencesArray} profileId={profileId} />
            }
            className={css.tooltip}
            trigger="click"
            theme="pf-light"
            interactive
          >
            <div className={classNames(css.link, { [css.linkDisabled]: isDisabled })}>
              {t("showInternalExperiencesButtonLabel")}
            </div>
          </Tooltip>
        </div>
      )}
      {othersWithIt > 0 && (
        <div className={classNames(css.line, css.lastLine)}>
          <div>{t("othersWithIt")}</div>
          <div className={css.data}>{othersWithIt}</div>
        </div>
      )}
      {rankingValues && <RankingIndicator values={rankingValues} myRanking={ownExperience} />}
    </div>
  );
};

SkillBadgeMetadata.propTypes = {
  addedOn: PropTypes.string,
  usedFor: PropTypes.number,
  skillSource: PropTypes.string,
  othersWithIt: PropTypes.number,
  internalExperiences: PropTypes.array,
  rankingValues: PropTypes.shape({
    basic: PropTypes.number,
    intermediate: PropTypes.number,
    advanced: PropTypes.number
  }),
  ownExperience: PropTypes.number,
  isDisabled: PropTypes.bool,
  profileId: PropTypes.number,
  currentProfile: PropTypes.shape({
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string
      })
    ),
    permissions_group: PropTypes.shape({
      id: PropTypes.number,
      cross_group_permissions: PropTypes.object
    })
  })
};

export default SkillBadgeMetadata;
